<template>
    <div class="m-bargain m-scroll" ref="scroll" @scroll="handleScroll($event)">
        <!-- 头部导航 -->
        <div class="m-header" :style="`opacity:${(200 - this.offsetTop)  / 100};`">
            <img src="@/assets/goods/icon_back_2@2x.png" alt="" @click="$router.push('/en')">
            <p></p>
            <img src="@/assets/bargain/update.png" alt="" @click="getData()">
        </div>
        <div class="bargain-top">
            <van-swipe :autoplay="5000" lazy-render>
                <van-swipe-item v-for="(item, index) in goods.round_pic_url" :key="index">
                    <img :src="item.url" alt="">
                </van-swipe-item>
            </van-swipe>
            <div class="top-tip">
                <div class="discount">
                    <img src="@/assets/bargain/off_en.png" alt="">
                </div>
                <div class="surplus">
                    <p>Night Market discount <br> express pass</p>
                    <p>Remaining：<span>{{goods.goods_sku.sku_stock.stock}}</span></p>
                </div>
            </div>
            <div class="rule" @click="ruleShow = true">Rules</div>
        </div>
        <div class="bargain-content">
            <div class="bargain-ticket">
                <div class="number">{{goods.goods_info.name}}</div>
                <!-- <div class="mooby">*MoobyYoho Richmond Night Market unique designated platform</div> -->
                <div class="official" v-if="user.id == ''">
                    <p><span>Market Price: ${{goods.max_price}}</span></p>
                </div>
                <div class="price" v-else>
                    $<span>{{(goods.goods_sku.price - user.get_off_price).toFixed(2)}}</span> <span v-if="(goods.goods_sku.price - user.get_off_price).toFixed(2) != goods.goods_sku.price">${{goods.goods_sku.price}}</span>
                </div>
            </div>
            
            <div class="bargain-price" v-if="user.remain_off_price != 0">
                Congrats! You got <span> ${{user.get_off_price}}</span> OFF <br>(Maximum obtainable $17.5 off)
            </div>
            <div class="bargain-price" v-else>
                Got Discount for <span>${{user.get_off_price}}</span>
            </div>
            <van-progress :percentage="user.get_off_total*2" stroke-width="10" :show-pivot="false" />
            <div v-if="user.get_off_total != 50">
                <div class="share-btn" @click="handleShare">
                    {{user.id == '' ? 'Get discount now' : 'Click here to get more discount'}}
                </div>
                <div class="buy" v-if="user.get_off_price != '0.00'" @click="handleBuy">
                    Buy Now with current price
                </div>
            </div>
            <div v-else>
                <div class="share-btn" @click="handleBuy">
                    Buy Now
                </div>
            </div>
            <!-- <div class="bargain-count">
                <div class="dashed"></div>
                <img class="zhong" src="@/assets/bargain/Union-min.png" alt="">
                <img class="xing" src="@/assets/bargain/xingxing.png" alt="">
                <div class="start">
                    <p>Night Market</p>
                    <p>Starts in</p>
                </div>
                <van-count-down :time="action.start_time*1000 - time" >
                    <template #default="timeData">
                        <span class="block">{{ timeData.days }}</span>
                        <span class="colon">Day</span>
                        <span class="block">{{ timeData.hours }}</span>
                        <span class="colon">H</span>
                        <span class="block">{{ timeData.minutes }}</span>
                        <span class="colon">Min</span> 
                    </template>
                </van-count-down>
            </div> -->
        </div>
        <div class="bargain-detail">
            <div class="detail-top">
                <p class="detail-bg" :style="{transform: active == 2 ?'':'rotateY(180deg)'}"></p>
                <div class="detail-title">
                    <div @click="handleSee(1)">
                        <img src="@/assets/bargain/Group-min.png" alt=""> 
                        <span>How to pick up tickets</span>
                    </div>
                    <div @click="handleSee(2)">
                        <img src="@/assets/bargain/Vector-min.png" alt="">
                        <span>Invitation history</span>
                    </div>
                </div>
            </div>
            <div class="detail-content" :style="active == 1? conetentStyle1 : conetentStyle2">
                <div class="detail-l" v-show="active == 1">
                    <img :src="item.url" alt="" v-for="item in goods.goods_info.goods_details_url" :key="item.pic_id">
                </div>
                <div class="detail-r" v-show="active == 2">
                    <div class="r-item" v-for="item in helpList" :key="item.id">
                        <div class="item-time">
                            <p>A Friend joined the game</p>
                            <p>{{item.created_at}}</p>
                        </div>
                        <div class="item-price">
                            <p>Got discount for</p> <span>${{item.off_price}}</span>
                        </div>
                    </div>
                    <div class="no-help" v-if="helpList.length == 0">—— &nbsp;No more&nbsp; ——</div>
                </div>
            </div>
        </div>
        <!-- 成功开启砍价活动弹窗 -->
        <van-overlay :show="show">
            <div class="wrapper">
                <div class="tip">
                    <img src="@/assets/bargain/coupon_en.png" alt="">
                    <div class="know"  @click="handleKnow"></div>
                </div>
            </div>
        </van-overlay>
        <!-- 活动规则弹窗 -->
        <van-popup v-model="ruleShow">
            <p>RULES</p>
            <div class="rule">
                <div class="rule-title">1. How to participate in this activity</div>
                <div class="rule-content">
                    <span>·Only Canada based users can be particiated. Log in MoobyYoho.com to initiate the activity.  Participants can save activity poster or link, and forward it to friends via social media. To get more discounts ,you need to invite friends to join this activity.</span>
                    <div><a href="https://www.moobyyoho.com">*Mooby Yoho：www.moobyyoho.com</a></div>
                    <span>·Help friends to get ticket discounts: user can help friend to get 1 time ticket discount through scan the QR code of the shared poster or click the shared link . Each user(multiple devices in the same network environment are considered as the same user) can only help another user to get discount.</span>
                </div>
                <div class="rule-title">2. Discount Amount</div>
                <div class="rule-content">
                    ·Discount will pop up by a randomly order base, and when the pass discount reached $17.5 in totally, the item cannot continue to receive the discount anymore.
                </div>
                <div class="rule-title">3. Activity Time</div>
                <div class="rule-content">
                    ·The activity is subject to the official requirements of Richmond Night Market, and the first round of participating tickets is 200 tickets. First Come First Serve based.
                </div>
            </div>
            <div class="confirm" @click.stop="ruleShow = false">Confirm</div>
        </van-popup>
        <div class="down" v-if="downShow"  @click="handleDown">
            <img src="@/assets/bargain/down.png" alt="">
        </div>
    </div>
</template>
<script>
import { market_info, join, off_log, coupon_unread } from '@/api/zh/bargain.js'

export default {
    name: 'share',
    data(){
        return {
            price: 17.5,
            offsetTop: 0,
            time: new Date().getTime(),
            action: {}, // 活动信息
            goods: {goods_sku:{sku_stock:{}}, goods_info: {}}, // 商品信息
            user: {get_off_price: 0, remain_off_price: 17.5, get_off_total: 0,id:'',ip:''}, // 砍价信息
            active: '1',
            show: false,  // 提示弹窗
            ruleShow: false, // 活动规则弹窗
            conetentStyle1: {
                'border-top-right-radius': '2.13333vw' 
            },
            conetentStyle2: {
                'border-top-left-radius': '2.13333vw' 
            },
            helpList: [],  // 好友帮助列表
            flag: false, // 判断是否已经参与活动  默认false   
            downShow: true  
        }
    },
    methods:{
        handleScroll(e) {
            this.$nextTick(() => {
                this.offsetTop = e.target.scrollTop
                if(this.offsetTop >= 150) {
                    this.downShow = false
                } else {
                    this.downShow = true
                }
            })
        },
        // 获取活动信息
        getData() {
            this.$store.commit('load')
            market_info().then(res => {
                if(res.code == 20000) {
                    this.action = res.data.action
                    this.goods = res.data.goods
                    if(res.data.user != null) {
                        this.user = res.data.user
                        this.flag = true
                        this.getHelpList()
                        this.couponNotify()
                    } else {
                        this.flag = false
                    }
                    // if(localStorage.getItem('IKnow')) {
                    //     this.show = false
                    // }
                }
            }).finally(() => {
                this.$store.commit('unload')
            })
        },
        couponNotify() {
            coupon_unread().then(res => {
                if(res) {
                    if(JSON.stringify(res.data) === '{}') {
                        this.show = false
                    } else {
                        this.show = true
                    }
                }
            })
        },
        // 获取好友助力列表
        getHelpList() {
            off_log().then(res => {
                if(res.code == 20000) {
                    this.helpList = res.data.data
                }
            })
        },
        // 参与活动
        handleShare() {
            if(!this.flag) {
                join().then(res => {
                    if(res) {
                        this.$router.push({path:'/en/bargain/share', query: {id: res.data.id}})
                    }
                })
            } else {
                // if(localStorage.getItem('token')) {
                    setTimeout(() => {
                        this.$router.push({path:'/en/bargain/share', query: {id: this.user.id}})
                    },500)
                // }
            }
            let data = {
                price: this.goods.goods_sku.price,
                stock: this.goods.goods_sku.sku_stock.stock,
                barcode: this.goods.goods_sku.barcode,
                id: this.goods.goods_sku.goods_id,
            }
            sessionStorage.setItem('bargainShare', JSON.stringify(data))
            
        },
        // 购买
        handleBuy() {
            if(this.user.order_info_id == 0) {
                this.$router.push('/en/bargain/buy')
            } else {
                this.$router.push('/en/order/0?back=account')
            }
            
        },
        handleKnow() {
            this.show = false
            // localStorage.setItem('IKnow', true)
        },
        handleSee(e) {
            this.active = e
            localStorage.setItem('active', e)
        },
        handleDown() {
             this.downTimer = setInterval(() => {
               this.$refs.scroll.scrollTop = this.$refs.scroll.scrollTop + 10
               if(this.$refs.scroll.scrollTop >= 150) {
                   clearInterval(this.downTimer);
               }
            }, 10)
        }
        
    },
    created() {
        if(localStorage.getItem('active')) {
            this.active = localStorage.getItem('active')
        }
        this.getData()
    }
}
</script>
<style lang="less" scope>
.m-bargain {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background: #f2f2f2;
    .m-header {
        width: 100%;
        height: 44px;
        background-color: transparent;
        padding: 0 12px 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        img {
            width: 24px;
            height: 24px;
            position: relative;
            z-index: 1;
        }
        p {
            font-size:18px;
            font-weight:400;
            color:#fff;
            line-height:25px;
            letter-spacing:1px;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            line-height: 44px;
            padding: 0 80px;
        }
    }
    .bargain-top {
        width: 100%;
        height: 246px;
        background: #efefef;
        position: relative;
        .van-swipe {
            height: 100%;
            .van-swipe-item {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .rule {
            position: absolute;
            right: 0;
            bottom: 76px;
            width: 78px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 14px 0 0 14px;
            font-size: 12px;
            background: #fff;
            color: #F35F42;
        }
        .top-tip {
            position: absolute;
            bottom: -1px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 60px;
            font-size: 12px;
            background: url('../../../assets/bargain/tip.png') no-repeat;
            background-size: 100% 100%;
            .discount {
                margin-left: 12px;
                margin-top: 5px;
                width: 170px;
                height: 44px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .surplus {
                color: #5A0000;
                margin-right: 10px;
                text-align: center;
                p:nth-child(2) {
                    span {
                        font-style: italic;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .bargain-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;
        border-radius: 0 0 8px 8px;
        background: #fff;
        margin-bottom: 10px;
        .bargain-ticket {
            margin-top: 20px;
            width: 100%;
            font-size: 16px;
            text-align: left;
            padding: 0 20px;
            .number {
                font-weight: 600;
            }
            .mooby {
                color: #5989ED;
                font-size: 12px;
                margin:5px 0 10px;
            }
            .official {
                margin-bottom: 15px;
                margin-top: 5px;
                font-size: 14px;
            }
            .price {
                margin-bottom: 15px;
                margin-top: 5px;
                color: #ED2A24;
                font-size: 16px;
                font-weight: bold;
                span:nth-child(1) {
                    font-size: 24px;
                    color: #ED2A24;
                    text-decoration:none;
                }
                span:nth-child(2) {
                    color: #999;
                    font-size: 12px;
                    font-weight: normal;
                    text-decoration:line-through;
                }
            }
        }
        .bargain-count {
            position: relative;
            width: 343px;
            height: 58px;
            border-radius: 0 0 15px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
            text-align: center;
            background: linear-gradient(271.71deg,  #FFC7B4 -2.49%, #FFE9D8 101.72%);
            .dashed {
                position: absolute;
                left: 50%;
                top: -1px;
                transform: translateX(-50%);
                width: 351px;
                border-top: 2px dashed #5D8DEC;
            }
            .zhong {
                position: absolute;
                top: 2px;
                left: 5px;
                width: 37px;
                height: 45px;
            }
            .xing {
                position: absolute;
                bottom: 9px;
                right: 3px;
                width: 16px;
                height: 20px;
            }
            .start {
                margin-right: 10px;
                margin-left: 10px;
                z-index: 2;
                p:nth-child(2) {
                    color: #591400;
                    font-size: 16px;
                    font-weight: 700;
                }
                p:nth-child(1) {
                    color: #CA7153;
                    font-size: 12px;
                }
            }
            .van-count-down {
                .block {
                    display: inline-block;
                    width: 36px;
                    height: 36px;
                    border-radius: 5px;
                    background: #fff;
                    text-align: center;
                    line-height: 36px;
                    font-weight: 700;
                }
                .colon {
                    margin: 0 8px;
                    
                }
            }
        }
        .bargain-price {
            font-size: 14px;
            color: #808080;
            text-align: center;
            span {
                color: #EF5D56;
                font-weight: bold;
            }
        }
        .van-progress {
            width: 300px;
            margin: 4px 0 16px;
            .van-progress__portion {
                background: linear-gradient(90.19deg, #6EFFF6 -6.08%, #35B6FF 105.58%);
            }
        }
        .share-btn {
            font-size: 16px;
            color: #fff;
            width: 311px;
            height: 44px;
            line-height: 44px;
            border-radius: 22px;
            margin-bottom: 15px;
            background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
            text-align: center;
        }
        .buy {
            width: 200px;
            height: 21px;
            font-size: 16px;
            text-align: center;
            text-decoration: underline;
            margin:0 auto 16px;
            color: #004EBD;
        }
    }
    .bargain-detail {
            width: 100%;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0px 4px 16px rgba(128, 128, 128, 0.1);
            background: #FDF4F0;
            margin-bottom: 10px;
            overflow: hidden;
            .detail-top {
                height: 45px;
                width: 100%;
                position: relative;
                .detail-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 46px;
                    background: url('../../../assets/bargain/detail.png') no-repeat;
                    background-size: 100% 100%;
                }
                .detail-title {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    &>div {
                        flex: 1;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 15px 0;
                        font-size: 12px;
                        img {
                            width: 18px;
                            // height: 16px;
                            vertical-align: middle;
                            margin-right: 5px;
                        }
                        span {
                            display: inline-block;
                            width: 130px;
                        }
                    }
                }
            }
            .detail-content {
                background: #fff;
                overflow: hidden;
                .detail-l {
                    width: 100%;
                    font-size: 0;
                    img {
                        width: 100%;
                    }
                }
                .detail-r {
                    width: 100%;
                    padding: 20px;
                    .r-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 12px;
                        border-bottom: 1px solid #EFEFEF;
                        padding: 5px 0 15px;
                        .item-time{
                            p:nth-child(1) {
                                font-size: 14px;
                                margin-bottom: 5px;
                            }
                            p:nth-child(2) {
                                color: #999999;
                            }
                        }
                        .item-price {
                            text-align: right;
                            span {
                                color: #ED2A24;
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                    }
                    .no-help {
                        text-align: center;
                        color: #cecece;
                    }
                }
            }
        }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tip {
            width: 288px;
            height: 345px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                
            }
            .know {
                position: absolute;
                bottom: 12px;
                left: 50%;
                transform: translateX(-50%);
                width: 170px;
                height: 40px;
            }
        }
    }
    .van-overlay {
        z-index: 99 !important;
    }
    .van-popup {
        width: 291px;
        padding: 20px 20px 0;
        background: #fff;
        border-radius: 12px;
        font-size: 14px;
        box-sizing: border-box;
        p {
            text-align: center;
            margin-bottom: 12px;   
            font-size: 16px;       
            font-weight: 600;
        }
        .rule {
            overflow-x: hidden;
            overflow-y: auto;
            height: 377px;
            .rule-title {
                font-weight: 600;
                margin-bottom: 12px;
            }
            .rule-content {
                margin-bottom: 20px;
                div {
                    margin-bottom: 5px;
                    a {
                        color: #48A2FF;
                    }
                }
            }
        }
        .confirm {
            margin-top: 5px;
            box-shadow: inset 0px 1px 0px rgba(153, 153, 153, 0.25);
            text-align: center;
            padding: 13px 0;
            color: #FF5049;
        }
    }
    .down {
        position: fixed;
        right: 2px;
        bottom: 10%;
        width: 36px;
        height: 36px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>